import type { ReactNode } from 'react';

import ShoppingCartIcon from '@heroicons/react/24/outline/ShoppingCartIcon';
import { Button } from 'atp-react-ui';

import { useSelfStudyStore } from '../@shared';

export function Layout({ children }: { children: ReactNode }) {
  const { openCart, addedItems } = useSelfStudyStore((store) => store);

  const count = addedItems.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <>
      {/* <Header /> */}
      <div className="hidden border-b border-transparent bg-gray-25 pb-0">
        <main className="container mx-auto max-w-full px-6 sm:max-w-[44rem] sm:px-4 md:max-w-[48rem] md:px-6 pre-lg:max-w-[60rem] pre-lg:px-4 lg:max-w-none lg:px-11 pre-xl:px-4 xl:max-w-7xl xl:px-3">
          <header className="pt-8">
            <div className="flex flex-row items-center justify-between gap-x-3 border-b border-outline pb-0 md:flex-row">
              <nav className="-mb-px flex w-full space-x-2">
                <a
                  title="Study Guide"
                  className="course-tab-courses-unregistered selected group relative mx-px py-2 after:absolute after:inset-x-0 after:bottom-0 after:h-0 after:rounded-tl-md after:rounded-tr-md after:border-t-4 after:border-solid after:border-primary after:content-['']"
                >
                  <button
                    className="relative inline-flex cursor-pointer select-none items-center justify-center whitespace-nowrap rounded-lg border border-transparent px-4 py-[0.4375rem] align-bottom text-xs font-medium tracking-[0.16px] text-accent-light shadow-none outline-none transition-colors duration-150 hover:bg-primary-x-light hover:text-primary hover:shadow-none focus:ring-0 focus:ring-primary-light active:bg-transparent disabled:cursor-not-allowed disabled:border-outline disabled:bg-gray-50 disabled:text-outline group-[.selected]:text-primary group-[.selected]:hover:text-primary"
                    type="button"
                  >
                    Study Guide
                  </button>
                </a>
                <a title="Practice Exams" className="course-tab-courses-in-progress relative mx-px hidden py-2">
                  <button
                    className="relative inline-flex cursor-pointer select-none items-center justify-center whitespace-nowrap rounded-lg border border-transparent px-4 py-[0.4375rem] align-bottom text-xs font-medium tracking-[0.16px] text-accent-light shadow-none outline-none transition-colors duration-150 hover:bg-primary-x-light hover:text-primary hover:shadow-none focus:ring-0 focus:ring-primary-light active:bg-transparent disabled:cursor-not-allowed disabled:border-outline disabled:bg-gray-50 disabled:text-outline group-[.selected]:text-primary group-[.selected]:hover:text-primary"
                    type="button"
                  >
                    Practice Exams
                  </button>
                </a>
              </nav>
              <div className="relative">
                <Button
                  icon={ShoppingCartIcon}
                  as="link"
                  size="sm"
                  onClick={() => {
                    openCart(true);
                  }}
                />
                {!!count && (
                  <div className="absolute -right-1 -top-1 flex size-4 items-center justify-center rounded-full bg-primary p-0 text-center text-[0.625rem] font-medium text-white">
                    {count}
                  </div>
                )}
              </div>
            </div>
          </header>
        </main>
      </div>
      <main className="container mx-auto max-w-full px-6 pb-20 sm:max-w-[44rem] sm:px-4 md:max-w-[48rem] md:px-6 pre-lg:max-w-[60rem] pre-lg:px-4 lg:max-w-none lg:px-11 pre-xl:px-4 xl:max-w-7xl xl:px-3">
        <section className="courses-unregistered">{children}</section>
      </main>
    </>
  );
}
