import { forwardRef } from 'react';

import { FormControl, FormItem, FormLabel, FormMessage } from 'atp-react-ui';
import type { FormLabelProps } from 'atp-react-ui/dist/components/form-components/types';

import type { PatternInputProps } from './pattern-input';
import { PatternInput } from './pattern-input';

export type FormPatternInputProps = PatternInputProps & FormLabelProps;

export const FormPatternInput = forwardRef<HTMLInputElement, FormPatternInputProps>(
  ({ label, labelClassName, formItemClassName, value, ...props }, ref) => (
    <FormItem className={formItemClassName}>
      {label && <FormLabel className={labelClassName}>{label}</FormLabel>}
      <FormControl>
        <PatternInput value={value} {...props} ref={ref} />
      </FormControl>
      <FormMessage />
    </FormItem>
  )
);

FormPatternInput.displayName = 'FormPatternInput';
