import React from 'react';

import { CookieManager } from '@/_services';
import { logoutUser } from '@/_shared/utils/user';

export const useAuth = () => {
  const login = React.useCallback(
    ({
      accessToken,
      refreshToken,
      loginMethod,
    }: {
      accessToken: string;
      refreshToken: string;
      loginMethod: 'url' | 'normal';
    }) => {
      CookieManager.setAuthTokens(accessToken, refreshToken, loginMethod);
    },
    []
  );

  return { login, logout: logoutUser };
};
