import type { ReactNode } from 'react';

import { Container } from '@/_components';

import { Header } from '../@shared';

export function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <Header />

      <Container>
        <section className="courses-unregistered pb-10">{children}</section>
      </Container>
    </>
  );
}
