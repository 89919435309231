import { Skeleton } from 'atp-react-ui';

const items = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default function Loading() {
  return (
    <ul className="page-grid mt-4">
      {items.map((item) => (
        <li className="page-grid-element" key={item}>
          <div className="course-card-books prep-course-card flex min-w-0 flex-col divide-outline overflow-hidden rounded-lg bg-white shadow-card">
            <article className="course-card-article">
              <header className="course-card-article-header border-outline/30">
                <Skeleton className="h-28 w-full rounded-b-none" />
              </header>
              <section className="course-card-article-body">
                <div className="px-6">
                  <Skeleton className="h-6 w-3/4" />

                  <div className="mt-2 flex items-center justify-between">
                    <div className="hidden items-center gap-1">
                      <Skeleton className="h-5 w-5" />
                      <Skeleton className="h-5 w-5" />
                      <Skeleton className="h-5 w-5" />
                      <Skeleton className="h-5 w-5" />
                      <Skeleton className="h-5 w-5" />
                      <Skeleton className="h-5 w-5" />
                    </div>
                    <Skeleton className="h-6 w-12" />
                  </div>
                </div>
              </section>
              <footer className="mt-2 flex w-full flex-shrink-0 gap-2 px-4 pb-4 pt-2">
                <Skeleton className="h-[2.375rem] flex-1" />
                <Skeleton className="h-[2.375rem] flex-1" />
              </footer>
            </article>
          </div>
        </li>
      ))}
    </ul>
  );
}
