import { Skeleton, twMerge } from 'atp-react-ui';

export function InputSkeleton({ className }: { className?: string }) {
  return (
    <section className="flex flex-col gap-y-1.5">
      <Skeleton className="h-[1.124rem] w-28" />
      <Skeleton className={twMerge('h-[2.625rem] w-2/3 md:w-full', className)} />
    </section>
  );
}
