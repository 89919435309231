import { z } from 'zod';

const instructorInfoSchema = z.object({
  instructor_name: z.string(),
  instructor_job_title: z.string(),
  instructor_department: z.string(),
  instructor_team: z.string().nullable(),
  instructor_educational_background: z.string(),
  instructor_image_url: z.string().nullable(),
  instructor_bio: z.string(),
});

export type InstructorInfoSchema = z.infer<typeof instructorInfoSchema>;

const ScheduledClassSchema = instructorInfoSchema.extend({
  day_s_of_the_week: z.string(),
  end_date: z.string(),
  end_time: z.string(),
  id: z.string(),
  start_date: z.string(),
  start_time: z.string(),
  time_formula: z.string(),
  employee_id: z.string(),
});

export type ScheduledClassType = z.infer<typeof ScheduledClassSchema>;

const eligibleScheduledClassSchema = z.object({
  next_start: z.string().nullable(),
  no_of_eligible_scheduled_classes: z.number(),
  scheduled_classes: z.array(ScheduledClassSchema),
});

const unregisteredCourseSchema = z.object({
  id: z.string(),
  name: z.string(),
  length: z.string(),
  description: z.string(),
  eligible_scheduled_classes: eligibleScheduledClassSchema,
  exam_standard: z.string(),
  ranking: z.string(),
  study_guide_applied: z.boolean(),
  e_book: z.boolean(),
  manual_revisions: z.string(),
  non_atp_study_guide: z.boolean(),
  is_in_house: z.boolean(),
  check_completed_courses: z.boolean(),
  nursing_question_text: z.string().nullable(),
  enrollment_id: z.string(),
  nursing_question_s_answer: z.unknown().nullable(),
  course_image: z.string(),
  rolling_course: z.boolean(),
});

export type UnregisteredCourseSchema = z.infer<typeof unregisteredCourseSchema>;

export const UnregisteredCoursesApiResponseSchema = z.array(unregisteredCourseSchema);

export type UnregisteredCoursesApiResponseType = z.infer<typeof UnregisteredCoursesApiResponseSchema>;
