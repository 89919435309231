import type { ReactNode } from 'react';

import { Text } from 'atp-react-ui';

import { Container } from '@/_components';

import { CartButton } from '../@shared';

export function Layout({ children }: { children: ReactNode }) {
  return (
    <Container>
      <section className="mb-4 mt-8 flex items-center justify-between">
        <Text as="caption-medium-bold" className="uppercase">
          Available Study Guides
        </Text>
        <div>
          <CartButton />
        </div>
      </section>
      <section className="courses-unregistered pb-10">{children}</section>
    </Container>
  );
}
