import { UsaStatesOptions } from '@/_config';

import type { AddCreditCardFormSchema } from './schema';

export const defaultValues: Readonly<AddCreditCardFormSchema> = {
  name: '',
  card_number: '',
  expiry: '',
  cvv: '',
  state: UsaStatesOptions[0],
  zip_code: '',
} as const;
