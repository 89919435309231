import React from 'react';

import EyeIcon from '@heroicons/react/24/outline/EyeIcon';
import EyeSlashIcon from '@heroicons/react/24/outline/EyeSlashIcon';
import { Button, useBoolean } from 'atp-react-ui';
import { FormPatternInput } from 'atp-react-ui';

type FormPatternInput = React.ComponentProps<typeof FormPatternInput>;

const FormPatternPasswordInput = React.forwardRef<HTMLInputElement, FormPatternInput>(
  ({ className, type = 'text', ...props }, ref) => {
    const isSecureInput = type === 'password';

    const [isHidden, hide] = useBoolean(isSecureInput);

    return (
      <div className="relative">
        <FormPatternInput className={`${className} pr-11`} ref={ref} type={isHidden ? 'password' : 'text'} {...props} />
        {isSecureInput && (
          <Button
            icon={isHidden ? EyeIcon : EyeSlashIcon}
            onClick={hide.toggle}
            size="sm"
            as="link"
            className="absolute right-[0.3125rem] top-7"
          />
        )}
      </div>
    );
  }
);

FormPatternPasswordInput.displayName = 'FormPatternPasswordInput';

export { FormPatternPasswordInput };
