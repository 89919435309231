import { Suspense, lazy } from 'react';

import { Layout } from './layout';
import Loading from './loading';

const Page = lazy(() => import('./page'));

export function StudyGuides() {
  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Page alreadyBought={[]} />
      </Suspense>
    </Layout>
  );
}
