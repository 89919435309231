import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { defaultValues as personalDefaultValues, type PersonalInfoCheckoutFormSchema } from './personal-info-form';

type SelfStudyStore = {
  isCartOpen: boolean;
  isPersonalDetailsOpen: boolean;
  isPaymentDetailsOpen: boolean;
  isNewItemAdded: boolean;
  addedItems: { itemId: string; quantity: number }[];
  formValues: PersonalInfoCheckoutFormSchema;

  saveFormValue: (formValues: PersonalInfoCheckoutFormSchema) => void;
  saveToCart: (bookIds: { itemId: string; quantity: number }[], isNewItemAdded?: boolean) => void;
  openCart: (isCartOpen: boolean) => void;
  openPersonalDetails: (isPersonalDetailsOpen: boolean) => void;
  openPaymentDetails: (isCheckoutOpen: boolean) => void;
  resetStoreState: () => void;
};

const getDefaultValues = () => ({
  isCartOpen: false,
  isPersonalDetailsOpen: false,
  isPaymentDetailsOpen: false,
  isNewItemAdded: false,
  addedItems: [],
});

export const useSelfStudyStore = create<SelfStudyStore>()(
  persist(
    (set) => ({
      ...getDefaultValues(),
      formValues: { ...personalDefaultValues },

      saveFormValue: (formValues: PersonalInfoCheckoutFormSchema) => set({ formValues }),

      saveToCart: (addedItems: { itemId: string; quantity: number }[], isNewItemAdded = false) =>
        set({ addedItems, isNewItemAdded }),

      openCart: (isCartOpen: boolean) => {
        if (isCartOpen) {
          set({ isCartOpen });
        } else {
          set({ isCartOpen, isNewItemAdded: false });
        }
      },
      openPersonalDetails: (isPersonalDetailsOpen: boolean) => set({ isPersonalDetailsOpen }),
      openPaymentDetails: (isPaymentDetailsOpen: boolean) => set({ isPaymentDetailsOpen }),

      resetStoreState: () => set({ ...getDefaultValues() }),
    }),
    {
      name: 'self-study-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['isCartOpen', 'isNewItemAdded', 'isPersonalDetailsOpen', 'isPaymentDetailsOpen'].includes(key)
          )
        ),
    }
  )
);
