import { STUDENT_STATUS } from '@/_config';
import { CookieManager } from '@/_services';

import { getAccessType } from '../../../_shared/policies';

import type { MeApiResponse, MeApiTransformedResponse } from './use-me-query';

export function transformMeApiResponse(data: MeApiResponse): MeApiTransformedResponse {
  const accountType = CookieManager.getAccountType();
  const isStudent = CookieManager.isStudentAccount();
  const isInstructor = CookieManager.isInstructorAccount();

  const { plan_summary } = data;
  const { atp_pilot, student_status, has_plan, enrollment_end_date, extension_ea_date, payment_type } =
    plan_summary || {};

  const accessType = getAccessType({
    payment_type,
    student_status,
    has_plan,
    enrollment_end_date,
    extension_ea_date,
    atp_pilot,
  });

  const canAccessPortalFeatures = student_status !== STUDENT_STATUS.SG_ENROLLED;

  return {
    ...data,
    accessType,
    localAccountType: accountType,
    isStudent,
    isInstructor,
    canAccessPortalFeatures,
  };
}
