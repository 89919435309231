import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import { Icon, twMerge } from 'atp-react-ui';

import { Container } from '@/_components';

import { CartButton } from './cart-button';

export function Header() {
  return (
    <header className="sticky top-0 z-10 h-[5.5rem] w-full border-b border-outline/60 bg-white">
      <Container className="container flex h-full items-center justify-between">
        <div>
          <img
            src="https://cdn.prod.website-files.com/65bba47c0c06f86d9140929c/6603b92118992bbac85e270a_achieve-logo.svg"
            loading="lazy"
            width="142"
            height="38"
            alt="Achieve Test Prep Logo"
          />
        </div>
        <div className="flex items-center gap-6 px-2">
          <Link name="Study Guides" nomore active />
          <CartButton />
        </div>
      </Container>
    </header>
  );
}

function Link({
  name,
  nomore = false,
  active = false,
}: {
  active?: boolean;
  name: string;
  path?: string;
  nomore?: boolean;
}) {
  return (
    <div
      className={twMerge(
        'flex cursor-pointer items-center px-2 text-base font-thin text-secondary-dark hover:text-primary',
        active && 'font-medium italic'
      )}
    >
      {name} {!nomore && <Icon icon={ChevronDownIcon} className="ml-1 mt-1" />}
    </div>
  );
}
