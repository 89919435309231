import ShoppingCartIcon from '@heroicons/react/24/outline/ShoppingCartIcon';
import { Button } from 'atp-react-ui';

import { useSelfStudyStore } from './self-study-store';

export function CartButton() {
  const { openCart, addedItems } = useSelfStudyStore((store) => store);

  const count = addedItems.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <div className="relative">
      <Button
        icon={ShoppingCartIcon}
        as="link"
        // size="sm"
        onClick={() => {
          openCart(true);
        }}
      />
      {!!count && (
        <div className="absolute -right-1 -top-1 flex size-4 items-center justify-center rounded-full bg-primary p-0 text-center text-[0.625rem] font-medium text-white">
          {count}
        </div>
      )}
    </div>
  );
}
