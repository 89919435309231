import { z } from 'zod';

import { FormValidation } from '@/_shared';

export const personalInfoCheckoutFormSchema = z.object({
  first_name: z.string().min(2),
  last_name: z.string().min(2),
  email: FormValidation.email,
  phone: FormValidation.mobilePhone,
  state: FormValidation.selectInput(),
  zip_code: FormValidation.requiredString,
});

export type PersonalInfoCheckoutFormSchema = z.infer<typeof personalInfoCheckoutFormSchema>;
