import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiClient, queryClient } from '@/_services';
import type { ApiEndpointMethod } from '@/_services';

const AodRecordSchema = z.object({
  has_aod_registered: z.boolean(),
  has_aod_enrollment: z.boolean(),
  aod_college_institution_name: z.string().nullable(),
});

export type AodRecord = z.infer<typeof AodRecordSchema>;

const DepositInfoSchema = z.object({
  remaining_balance: z.number(),
  deposit_met: z.boolean(),
});

export type DepositInfo = z.infer<typeof DepositInfoSchema>;

const CardInfoSchema = z.object({
  chargent_orders_card_type: z.unknown().nullable(),
  chargent_orders_card_last_4: z.unknown().nullable(),
  chargent_orders_card_expiration_month: z.string().nullable(),
  chargent_orders_card_expiration_year: z.string().nullable(),
});

export type CardInfo = z.infer<typeof CardInfoSchema>;

const TierDetailSchema = z.object({
  points: z.number().optional(),
});

export type TierDetail = z.infer<typeof TierDetailSchema>;

const ZinreloUserInfoSchema = z.object({
  loyalty_tier_name: z.string().optional(),
  uid: z.string().optional(),
  loyalty_enrol_time: z.string().optional(),
  loyalty_tier_id: z.string().optional(),
  tier_details: TierDetailSchema.optional(),
  available_points: z.number().optional(),
  referral_code: z.string().optional(),
  redeemed_points: z.number().optional(),
  tags: z.array(z.string()).optional(),
  awarded_points: z.number().optional(),
  user_status: z.string().optional(),
  access_token: z.string().optional(),
  epoch_time: z.string().optional(),
});

export type ZinreloUserInfo = z.infer<typeof ZinreloUserInfoSchema>;

const StudyGuideDeliverySchema = z.union([
  z.literal('Lulu 50% off Print Only + Cx pays Shipping'),
  z.literal('Lulu Free Print + Cx pays Shipping'),
  z.literal('Lulu Printing & Shipping Flat Rate- $8.95'),
  z.literal('Lulu Printing & Shipping Flat Rate- $11.95'),
  z.literal('Lulu Printing & Shipping - Cx pays Reg Rate'),
  z.literal('Internal Study Guide Delivery - Free'),
]);
export type StudyGuideDeliveryType = z.infer<typeof StudyGuideDeliverySchema>;

const LuluConfigSchema = z.object({
  allow: z.boolean(),
  allow_from: z.string(),
  internal_book_delivery: z.boolean(),
  study_guide_delivery: StudyGuideDeliverySchema,
});

export type LuluConfig = z.infer<typeof LuluConfigSchema>;

const ShippingAddressSchema = z.object({
  city: z.string(),
  country: z.string().nullable(),
  geocodeAccuracy: z.number().nullable(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  postalCode: z.string(),
  state: z.string(),
  street: z.string(),
});

export type ShippingAddressType = z.infer<typeof ShippingAddressSchema>;

const accountDependencyQuerySchema = z.object({
  id: z.string(),
  email: z.string(),
  username: z.string(),
  aod_records: AodRecordSchema,
  deposit_info: DepositInfoSchema,
  card_info: CardInfoSchema,
  prep_course_plan_url: z.string().nullable(),
  allow_rewards: z.boolean(),
  zinrelo_user_info: ZinreloUserInfoSchema.optional(),
  last_hardship_date: z.string().nullable(),
  shipping_address: ShippingAddressSchema.nullable(),
  lulu_config: LuluConfigSchema,
  first_login: z.string(),
  has_submitted_onboarding_survey: z.boolean().nullable(),
  tutoring_taken: z.boolean(),
  self_serve: z.boolean(),
  ach_campaign: z.object({
    eligible_for_ach_campaign: z.boolean(),
    is_default: z.boolean(),
  }),
  wellcoaches_agreement_c: z.boolean(),
  wellcoaches_agreement_completed_c: z.boolean(),
  send_contact_waive: z.string().nullable(),
});

export type AccountDependencyQuerySchema = z.infer<typeof accountDependencyQuerySchema>;
export const ACCOUNT_DEPENDENCY_QUERY_KEY = ['ACCOUNT_DEPENDENCIES'];

export const useAccountDependencies = () =>
  useSuspenseQuery<AccountDependencyQuerySchema, unknown, AccountDependencyQuerySchema>({
    queryKey: ACCOUNT_DEPENDENCY_QUERY_KEY,
    queryFn: async () =>
      apiClient.request({
        endpoint: '/account-dependencies/',
        responseSchema: accountDependencyQuerySchema,
      }),
  });

export const invalidateAccountDependenciesQuery = () =>
  queryClient.invalidateQueries({ queryKey: ACCOUNT_DEPENDENCY_QUERY_KEY });

type UseAccountDependenciesMutationParams<Response, Payload> = UseMutationOptions<Response, unknown, Payload> & {
  method?: Exclude<ApiEndpointMethod, 'GET'>;
};

export const useAccountDependenciesMutation = <Payload extends Partial<AccountDependencyQuerySchema>>({
  method = 'PATCH',
  ...options
}: UseAccountDependenciesMutationParams<AccountDependencyQuerySchema, Payload>) => {
  const { mutateAsync, data, isPending, isError, error } = useMutation<AccountDependencyQuerySchema, unknown, Payload>({
    ...options,
    mutationFn: async (payload) => apiClient.post({ method, endpoint: '/account-dependencies/', payload }),
    onSuccess: async (...args) => {
      await invalidateAccountDependenciesQuery();
      options.onSuccess?.call(undefined, ...args);
    },
  });

  return [mutateAsync, { data, isLoading: isPending, isError, error }] as const;
};
