import { Text } from 'atp-react-ui';

import { FullModal } from '@/_components';
import { useOnboardingContext } from '@/_providers';

export function OnboardingModal() {
  const { showOnboardingVideo, hideVideo } = useOnboardingContext();

  const onClose = () => {
    hideVideo();
  };

  return (
    <FullModal
      open={showOnboardingVideo}
      onClose={onClose}
      className="flex flex-col gap-6 md:max-w-5xl"
      disableInternalClosing
    >
      <div className="flex flex-col gap-1">
        <Text className="text-md text-left font-bold sm:text-2xl">Welcome to Achieve!</Text>
        <Text className="text-left text-xs sm:text-sm">Play the video to get familiar with your prep portal</Text>
      </div>
      <video
        controls
        preload="auto"
        className="rounded-xl"
        src="https://player.vimeo.com/progressive_redirect/playback/1064485814/rendition/2160p/file.mp4?loc=external&signature=b86341a7979ea4f9cfe23c19e7d1c44909112d5673a509c8911ce5428e5ff639&user_id=123784807"
        // src="https://player.vimeo.com/progressive_redirect/playback/1005885854/rendition/1080p/file.mp4?loc=external&signature=0e640cf2711ef1f2d89d21fe80cc3d9721b9765028649a664092ff99622bce92"
      />
    </FullModal>
  );
}
