import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { dateTime } from '@/_shared';

import type { LESSON_STATUS, PrepCourseLessonMeta } from './types';

export const endDateFixPmToAm = (sfEndDate: string, sfStartTime: string, sfEndTime: string) => {
  if (sfStartTime.toLowerCase().includes('pm') && sfEndTime.toLowerCase().includes('am')) {
    return dateTime.add(sfEndDate, 1, 'day').format('MM/DD/YYYY');
  }
  return sfEndDate;
};

// startTime and EndTime must be in UTC
export function sessionTimeCalc(
  oStartTime: string | Dayjs,
  endTime: string | Dayjs,
  cancelled: string[],
  delayed = '0'
) {
  const currentTime = dateTime.now();

  let hasStarted = false;
  let withinSixtyMinRange = false;
  let withinFifteenMinRange = false;
  let lectureOver = false;
  let statusDescription = '';
  let lessonStatus: LESSON_STATUS = 'SCHEDULED_TODAY';
  const delayedTime = parseInt(delayed || '0', 10);
  let startTime = typeof oStartTime === 'string' ? dateTime.parse(oStartTime) : oStartTime;
  let delayText = '';

  if (delayedTime > 0) {
    startTime = dateTime.add(oStartTime, delayedTime, 'minutes');
    delayText = `Today's class is delayed by ${delayedTime} minutes.`;
  }

  const difference = dateTime.subtract(startTime, currentTime.valueOf(), 'milliseconds');

  withinSixtyMinRange = dateTime.isInBetween(currentTime, {
    startTime: dateTime.subtract(startTime, 60, 'minutes'),
    endTime,
  });

  if (withinSixtyMinRange) {
    withinFifteenMinRange = dateTime.isInBetween(currentTime, {
      startTime: dateTime.subtract(startTime, 15, 'minutes'),
      endTime,
    });
  }

  if (withinFifteenMinRange) {
    hasStarted = dateTime.isInBetween(currentTime, { startTime, endTime });
  }

  if (!hasStarted) {
    lectureOver = dateTime.isAfter(currentTime, endTime, 'milliseconds');
  }

  lessonStatus = 'SCHEDULED_TODAY';
  statusDescription = startTime.format('[Today, at] hh:mm A EST');

  if (withinSixtyMinRange) {
    lessonStatus = 'WITHIN_60';
    statusDescription = `Will start in 00:${difference.format('mm')} minutes`;
  }

  if (withinFifteenMinRange) {
    lessonStatus = 'WITHIN_15';
    statusDescription = `Will start in ${difference.format('00:mm:ss')}`;
  }

  if (hasStarted) {
    lessonStatus = 'LIVE';
    statusDescription = 'Lesson is in progress.';
    delayText = '';
  }

  if (lectureOver) {
    lessonStatus = 'ENDED';
    statusDescription = `Live lesson has ended`;
    delayText = '';
  }

  if (cancelled.find((d) => d && dateTime.isSame(currentTime, d, 'date'))) {
    lessonStatus = 'CANCELED';
    statusDescription = `Today's lesson has canceled`;
    withinFifteenMinRange = false;
    delayText = '';
  }

  return {
    withinFifteenMinRange,
    statusDescription,
    lessonStatus,
    minToStart: difference.valueOf() / 1000 / 60,
    delayText,
  };
}

export function sfLessonTimeStampToLocalStr(
  times: { nextStartDate: string; nextStartTime: string; nextEndTime: string },
  addTime = true
) {
  const t = {
    ...times,
  };

  const { nextStartDate, nextStartTime, nextEndTime } = t;
  if (!nextStartDate || !nextStartTime || !nextEndTime) return {};

  const nextEndDate = addTime ? endDateFixPmToAm(nextStartDate, nextStartTime, nextEndTime) : nextStartDate;

  const startTimeDate = dateTime.getConvertedToLocalStamp(nextStartDate, nextStartTime).format();
  const endTimeDate = dateTime.getConvertedToLocalStamp(nextEndDate, nextEndTime).format();

  return {
    startTimeDate,
    endTimeDate,
  };
}

export function checkBookingStatus(zerothBooking: { gateway_response: { from: string; to: string } }) {
  if (zerothBooking?.gateway_response) {
    let res = null;
    const { gateway_response: { from, to } = {} } = zerothBooking;

    // const from = '2022-01-26T01:14:00-05:00';
    // const to = '2022-01-26T02:00:00-05:00';

    if (dateTime.isSame(new Date(), from)) {
      res = sessionTimeCalc(
        dateTime.utcToTimezone(dateTime.parse(from as string).utc()),
        dateTime.utcToTimezone(dateTime.parse(to as string).utc()),
        []
      );
    }

    const newTime = dateTime.add(from, -12, 'hours');
    const canEditBooking = !dateTime.isAfter(new Date(), newTime, 'milliseconds');
    return { ...zerothBooking, res, canEditBooking };
  }

  return zerothBooking;
}

export function isTodaysCourse(startTimeDate?: string, endTimeDate?: string) {
  const currentTime = dateTime.now();

  const startTime = dayjs(startTimeDate);
  const endTime = dayjs(endTimeDate);

  const isDateSame = dateTime.isSame(currentTime, startTime, 'date');
  const isInBetween = dateTime.isInBetween(currentTime, { startTime, endTime });

  return {
    isToday: isInBetween || isDateSame,
    startTime,
    endTime,
    isInBetween,
    isDateSame,
  };
}

export function parseLessonInfo(time: {
  next_start: string;
  start_time: string;
  end_time: string;
  instructor_getting_late: string;
  cancelled: string[];
}): {
  liveBadge: boolean;
  meta: PrepCourseLessonMeta;
} {
  const { next_start, start_time, end_time, cancelled, instructor_getting_late } = {
    ...time,
    // next_start: '12/12/2024',
    // start_time: '10:00PM',
    // end_time: '01:00 AM',
  };

  let liveBadge = false;
  const localStamps = sfLessonTimeStampToLocalStr({
    nextStartDate: next_start,
    nextStartTime: start_time,
    nextEndTime: end_time,
  });
  const { startTime, endTime, isToday } = isTodaysCourse(localStamps.startTimeDate, localStamps.endTimeDate);

  if (!(isToday && next_start && start_time && end_time)) {
    if (!next_start) {
      // Course ended
      return {
        liveBadge,
        meta: {
          localStamps,
          isToday,
          withinFifteenMinRange: false,
          statusDescription: startTime.format('dddd, M/D[ at] hh:mm A'),
          lessonStatus: 'UN_SCHEDULED',
          minToStart: 0,
          delayText: '',
        },
      };
    }
    return {
      liveBadge,
      meta: {
        localStamps,
        isToday,
        withinFifteenMinRange: false,
        statusDescription: startTime.format('dddd, M/D[ at] hh:mm A'),
        lessonStatus: 'SCHEDULED',
        minToStart: 0,
        delayText: '',
      },
    };
  }

  const res = sessionTimeCalc(startTime.utc(), endTime.utc(), cancelled, instructor_getting_late || '0');

  if (res.withinFifteenMinRange && res.lessonStatus !== 'CANCELED') {
    liveBadge = true;
  }

  return {
    liveBadge,
    meta: {
      localStamps,
      isToday,
      ...res,
    },
  };
}
