import { Text } from 'atp-react-ui';

import type { PrepCourseBodyProps } from './types';

export function PrepCourseBody({ name, subTitle, lesson }: PrepCourseBodyProps) {
  return (
    <div className="px-6">
      <Text as="overline-medium" className="text-accent-light">
        {subTitle}
      </Text>
      <Text as="h5" className="mt-1 text-primary">
        {name}
      </Text>
      {lesson && (
        <Text as="caption" className="mt-1 break-words text-accent-light">
          {lesson.day}, {lesson.startDate} - {lesson.endDate}
        </Text>
      )}

      {lesson?.nextTopic && (
        <div className="mt-4 flex-grow-0">
          <Text as="subtitle-small" className="text-accent">
            {lesson.isTodayOrNextTopic}
          </Text>
          <Text as="caption" className="mt-1 text-accent-light">
            {lesson.nextTopic}
          </Text>
        </div>
      )}
    </div>
  );
}
