import { config, pppRestrictedStudentStatus, STUDENT_STATUS } from '@/_config';
import type { IRouteModel } from '@/_navigation';

import { hasExpiredPlan } from './billing';
import { RESTRICTIONS_TYPE } from './constants';
import type { GetAccessTypeParams, GetAccessTypeResult } from './types';

function hasAccountIssues({
  student_status,
  has_plan,
  enrollment_end_date,
  extension_ea_date,
}: Pick<
  GetAccessTypeParams,
  'student_status' | 'has_plan' | 'enrollment_end_date' | 'extension_ea_date' | 'atp_pilot'
>) {
  /**
   * student_status has restrictions
   */
  if (pppRestrictedStudentStatus.includes(student_status)) {
    return true;
  }

  /**
   * has no plan
   * student_status is not enrolled neither paid in full
   */

  if (
    !has_plan &&
    student_status !== STUDENT_STATUS.ENROLLED &&
    student_status !== STUDENT_STATUS.SUBSCRIPTION_PAID_IN_FULL
  ) {
    return true;
  }

  /**
   * Not Atp pilot user
   * has expired EA date
   * has expired ext date
   */
  return hasExpiredPlan({ enrollment_end_date, extension_ea_date });
}

function isBookeoUser(payment_type: GetAccessTypeParams['payment_type']) {
  return payment_type === 'Online PIF (one payment)' || payment_type === 'Online PIF (multi payment)';
}

export const getAccessType = ({
  payment_type,
  student_status,
  has_plan,
  enrollment_end_date,
  extension_ea_date,
  atp_pilot,
}: GetAccessTypeParams): GetAccessTypeResult => {
  const isRestrictedAccount = hasAccountIssues({
    student_status,
    has_plan,
    enrollment_end_date,
    extension_ea_date,
    atp_pilot,
  });

  let accessType = isRestrictedAccount ? RESTRICTIONS_TYPE.PPP_ACCESS_BLOCKED : RESTRICTIONS_TYPE.ALL_ACCESS;
  if (isBookeoUser(payment_type)) {
    accessType = RESTRICTIONS_TYPE.PREP_ACCESS_ONLY;
  }

  return accessType;
};

export const isPathAllowed = (accessType: RESTRICTIONS_TYPE, routeMeta?: IRouteModel) => {
  if (!routeMeta) {
    return false;
  }

  if (accessType === RESTRICTIONS_TYPE.PPP_ACCESS_BLOCKED) {
    return routeMeta.shouldBlockPPPAccess;
  }

  if (accessType === RESTRICTIONS_TYPE.PREP_ACCESS_ONLY) {
    return !routeMeta.shouldAllowPrepAccessOnly;
  }

  return false;
};

export const isRewardsAllowed = (isAllowed = false) => (config.isProduction ? isAllowed : true);
