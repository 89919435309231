import ROUTES from './routes';

export const SELF_STUDY_ALLOWED_LINKS: string[] = [
  ROUTES.STUDY_GUIDES_PURCHASED.path,
  ROUTES.STUDY_GUIDES_AVAILABLE.path,
  ROUTES.STUDY_GUIDES.path,
  ROUTES.PROFILE.path,
  ROUTES.PROFILE_EDIT.path,
  ROUTES.PROFILE_CHANGE_PASSWORD.path,
  ROUTES.PROFILE_COURSES.path,
  ROUTES.RESOLVE_LOGIN.path,
  ROUTES.LOGOUT.path,
  ROUTES.MEET_AN_ADVISOR.path,
  ROUTES.COURSE_PRINT_BOOK.path,
];
