import { UsaStatesOptions } from '@/_config';

import type { PersonalInfoCheckoutFormSchema } from './schema';

export const defaultValues: Readonly<PersonalInfoCheckoutFormSchema> = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  state: UsaStatesOptions[0],
  zip_code: '',
} as const;
