import ArrowRightOnRectangleIcon from '@heroicons/react/24/outline/ArrowRightOnRectangleIcon';
import CakeIcon from '@heroicons/react/24/outline/CakeIcon';
import CreditCardIcon from '@heroicons/react/24/outline/CreditCardIcon';
import LifebuoyIcon from '@heroicons/react/24/outline/LifebuoyIcon';
import PuzzlePieceIcon from '@heroicons/react/24/outline/PuzzlePieceIcon';
import ScaleIcon from '@heroicons/react/24/outline/ScaleIcon';
import TicketIcon from '@heroicons/react/24/outline/TicketIcon';
import UserCircleIcon from '@heroicons/react/24/outline/UserCircleIcon';
import VideoCameraIcon from '@heroicons/react/24/outline/VideoCameraIcon';
import { Button, Dropdown, DropdownButton, DropdownItem, DropdownItems, twMerge, useButton } from 'atp-react-ui';
import { Link } from 'react-router-dom';

import { Avatar } from '@/_components';
import { ROUTES } from '@/_navigation';
import { useAuth, useMeContext, useOnboardingContext } from '@/_providers';

import type { NavbarUserInfo } from '.';

type AccountMenuProps = {
  user: NavbarUserInfo;
  isMobile?: boolean;
};

export function AccountMenu({ user, isMobile = false }: AccountMenuProps) {
  const profileMenuBtn = useButton({
    as: 'link',
    size: 'sm',
    className: twMerge('p-0 focus:ring-0', isMobile ? '-mx-3 px-4 py-2.5 hover:bg-gray-overlay' : ''),
  });
  const { logout } = useAuth();
  const { canAccessPortalFeatures } = useMeContext();
  const { showVideo } = useOnboardingContext();

  return (
    <Dropdown>
      <DropdownButton className={profileMenuBtn.buttonStyles}>
        <span className="sr-only">Open user menu</span>
        <Avatar
          size="xs"
          imageUrl={user.imageUrl}
          fallbackLabel={user.name}
          disableEditing
          disableChangeAvatar
          avatarImageProps={{ alt: 'profile picture' }}
          className="rounded-lg bg-white"
        />
      </DropdownButton>

      <DropdownItems className="p-2 pt-4">
        <DropdownItem>
          {({ close }) => (
            <Link to={ROUTES.PROFILE.path}>
              <Button className="border-0 px-4" as="dropdownItem" onClick={close} iconLeft={UserCircleIcon}>
                Profile
              </Button>
            </Link>
          )}
        </DropdownItem>
        {canAccessPortalFeatures && (
          <>
            <li className="mb-2 border-b border-outline" />
            <DropdownItem>
              {({ close }) => (
                <Link to={ROUTES.BILLING.path}>
                  <Button className="border-0 px-4" onClick={close} as="dropdownItem" iconLeft={CreditCardIcon}>
                    Billing
                  </Button>
                </Link>
              )}
            </DropdownItem>

            <DropdownItem>
              {({ close }) => (
                <Button
                  tag="a"
                  target="_blank"
                  href="https://help.achievetestprep.com/s/"
                  className="border-0 px-4"
                  as="dropdownItem"
                  iconLeft={LifebuoyIcon}
                  onClick={close}
                >
                  Help Center
                </Button>
              )}
            </DropdownItem>

            <DropdownItem>
              {({ close }) => (
                <Link to="/contact-support">
                  <Button className="border-0 px-4" onClick={close} as="dropdownItem" iconLeft={TicketIcon}>
                    Contact Support
                  </Button>
                </Link>
              )}
            </DropdownItem>

            <DropdownItem>
              {({ close }) => (
                <Button
                  tag="a"
                  target="_blank"
                  href="https://help.achievetestprep.com/s/article/Achieve-Holiday-Calendar-2025"
                  className="border-0 px-4"
                  as="dropdownItem"
                  iconLeft={CakeIcon}
                  onClick={close}
                >
                  Holiday Calendar
                </Button>
              )}
            </DropdownItem>

            <DropdownItem>
              {({ close }) => (
                <Link to="/terms">
                  <Button className="border-0 px-4" as="dropdownItem" onClick={close} iconLeft={ScaleIcon}>
                    Terms & Conditions
                  </Button>
                </Link>
              )}
            </DropdownItem>

            <DropdownItem>
              {({ close }) => (
                <Button
                  as="dropdownItem"
                  iconLeft={VideoCameraIcon}
                  onClick={() => {
                    showVideo();
                    close();
                  }}
                >
                  How to use
                </Button>
              )}
            </DropdownItem>

            <li className="mb-2 border-b border-outline" />

            <DropdownItem>
              {({ close }) => (
                <Link to={ROUTES.WHY_BRIDGE.path}>
                  <Button className="border-0 px-4" onClick={close} as="dropdownItem" iconLeft={PuzzlePieceIcon}>
                    Why Bridge
                  </Button>
                </Link>
              )}
            </DropdownItem>
          </>
        )}
        <li className="mb-2 border-b border-outline" />
        <DropdownItem>
          {({ close }) => (
            <Button
              className="border-0 px-4"
              onClick={() => {
                logout();
                close();
              }}
              as="dropdownItem"
              iconLeft={ArrowRightOnRectangleIcon}
            >
              Sign out
            </Button>
          )}
        </DropdownItem>
      </DropdownItems>
    </Dropdown>
  );
}
