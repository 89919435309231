import { useCallback, useRef } from 'react';

import { extractHttpsLink } from '../';

export function useOpenStudyGuide() {
  const bookRef = useRef<Window | null>(null);

  const handleOpenLink = useCallback((book_pdf_link: string) => {
    const pdfLink = extractHttpsLink(book_pdf_link);
    if (!pdfLink) {
      return;
    }
    if (!bookRef.current || bookRef.current?.closed) {
      const iframe = `<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0; overflow: hidden;}</style></head><body><iframe src="${pdfLink}#toolbar=0" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>`;

      bookRef.current = window.open(
        '',
        '',
        `width=${window.innerWidth}height=${window.innerHeight},toolbar=no,menubar=no,resizable=yes`
      );
      bookRef.current?.document.write(iframe);
    } else {
      bookRef.current.focus();
    }
  }, []);

  return { handleOpenLink };
}
