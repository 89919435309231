import { z } from 'zod';

import { FormValidation } from '@/_shared';

export const addCreditCardFormSchema = z.object({
  name: z.string().nonempty(),
  card_number: FormValidation.creditCard,
  expiry: z.string().nonempty().length(4),
  cvv: FormValidation.cvv,
  state: FormValidation.selectInput(),
  zip_code: FormValidation.requiredString,
});

export type AddCreditCardFormSchema = z.infer<typeof addCreditCardFormSchema>;
