import React from 'react';

import type { MeApiTransformedResponse } from '../hooks/use-me-query';

export type MeContextType = {
  data?: MeApiTransformedResponse;
  isSelfServe: boolean;
  canAccessPortalFeatures: boolean;
  isLoadingMeData: boolean;
  isFetching: boolean;
};

export const MeContext = React.createContext<MeContextType>({
  data: undefined,
  isLoadingMeData: true,
  isFetching: false,
  isSelfServe: false,
  canAccessPortalFeatures: false,
});
