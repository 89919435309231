export enum STUDENT_STATUS {
  AUDIT = 'Audit',
  DELETE = 'Delete',
  DOCUSIGN = 'Docusign',
  DROPPED = 'Dropped',
  DROPPED_INCOMPLETE_DEPOSIT = 'Dropped - Incomplete Deposit',
  GRADUATED = 'Graduated',
  OBSOLETE_NEW = 'OBSOLETE - New',
  ON_HOLD = 'On Hold',
  PRE_ENROLLED = 'Pre-Enrolled',
  PRE_ENROLLED_91 = 'Pre-Enrolled 91days+',
  PRE_ENROLLED_LATE = 'Pre-Enrolled Late',
  TEST = 'test',
  UNKNOWN = 'Unknown',
  WITHDRAWN = 'Withdrawn',
  SUBSCRIPTION_ENDED = 'Subscription Ended',
  ENROLLED = 'Enrolled',
  ATP_UNIFIED_CUSTOMER = 'ATP Unified Customer',
  ATP_SUBSCRIBER = 'ATP Subscriber',
  SUBSCRIPTION_PAID_IN_FULL = 'Subscription Paid In Full',
  BLOCKED = 'Blocked',
  SG_ENROLLED = 'SG Enrolled', // for Study Guide Enrolled Only
}

export const pppRestrictedStudentStatus = Object.freeze([
  STUDENT_STATUS.AUDIT,
  STUDENT_STATUS.DELETE,
  STUDENT_STATUS.DOCUSIGN,
  STUDENT_STATUS.DROPPED,
  STUDENT_STATUS.DROPPED_INCOMPLETE_DEPOSIT,
  STUDENT_STATUS.GRADUATED,
  STUDENT_STATUS.OBSOLETE_NEW,
  STUDENT_STATUS.ON_HOLD,
  // temp fix darci audit process in place to restrict pre-enrolled students-status users
  // STUDENT_STATUS.PRE_ENROLLED,
  STUDENT_STATUS.PRE_ENROLLED_91,
  STUDENT_STATUS.PRE_ENROLLED_LATE,
  STUDENT_STATUS.TEST,
  STUDENT_STATUS.UNKNOWN,
  STUDENT_STATUS.WITHDRAWN,
  STUDENT_STATUS.SUBSCRIPTION_ENDED,
  STUDENT_STATUS.BLOCKED,
]);

export const SwaPppRestrictedStudentStatus = Object.freeze([STUDENT_STATUS.ON_HOLD, STUDENT_STATUS.WITHDRAWN]);

export const preEnrolledStatus = Object.freeze([
  STUDENT_STATUS.PRE_ENROLLED,
  STUDENT_STATUS.PRE_ENROLLED_91,
  STUDENT_STATUS.PRE_ENROLLED_LATE,
  STUDENT_STATUS.DROPPED_INCOMPLETE_DEPOSIT,
]);

export enum PAYMENT_STATUS {
  DELINQUENT = 'Delinquent',
  HALF_PAY_IN_CLASS = 'Half Pay In Class',
}
