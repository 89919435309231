import { Skeleton } from 'atp-react-ui';

import { InputSkeleton } from '@/_components';
import AchieveLogo from '@/_shared/img/achieve-logo.svg?react';

export default function Loading() {
  return (
    <div className="flex flex-col gap-y-6">
      <div className="self-center">
        <AchieveLogo />
      </div>

      <div className="flex flex-col space-y-4">
        <Skeleton className="h-6 w-40 self-center" />

        <section className="flex flex-col gap-y-1.5">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-3/4" />
        </section>

        <InputSkeleton className="w-full" />
        <InputSkeleton className="w-full" />

        <div>
          <Skeleton className="mt-2 h-[2.625rem] w-full" />
        </div>
      </div>

      <div className="flex flex-col gap-y-6">
        <Skeleton className="h-8 w-32 self-center" />
      </div>
    </div>
  );
}
