import { StrictMode } from 'react';

import ReactDOM from 'react-dom/client';

import '@/_shared/styles/style.css';
import EntryPoint from './entry-point';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <EntryPoint />
  </StrictMode>
);
