import { Suspense, lazy } from 'react';

import Loading from '../study-guides/loading';

import { Layout } from './layout';

const Page = lazy(() => import('./page'));

export function BuyStudyGuides() {
  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Page />
      </Suspense>
    </Layout>
  );
}
