import { z } from 'zod';

import { personalInfoCheckoutFormSchema } from './personal-info-form';

export const bookSchema = z.object({
  id: z.string(),
  name: z.string(),
  image_detail: z.string(),
  image_link: z.string(),
  image_height: z.string(),
  image_width: z.string(),
  publisher: z.string(),
  image_detail_width: z.string(),
  image_detail_height: z.string(),
  pricing: z.number().nullable(),
  description: z.string().nullable(),
  image_url: z.string(),
  preview: z.string(),
});

export type BookSchema = z.infer<typeof bookSchema>;

export const purchasedBookSchema = bookSchema.extend({
  book_details: z.object({
    book_cover_pdf_link: z.string(),
    book_page_count: z.string(),
    book_pdf_link: z.string(),
    product_type: z.string(),
    virtual_link: z.string().nullable(),
  }),
});

export type PurchasedBookSchema = z.infer<typeof purchasedBookSchema>;

export const BOOKS_NOT_TO_SHOW = ['N108', 'N213', 'N212', 'N211', 'N209', 'N109', 'N105', 'N104'];

export const checkoutSchema = personalInfoCheckoutFormSchema.omit({ state: true }).extend({
  payment_method: z.string(),
  credit_cards: z.object({
    primary: z.object({
      name: z.string(),
      number: z.string(),
      exp_year: z.string(),
      exp_month: z.string(),
      cvv: z.string(),
    }),
  }),
  discounted_amount: z.number(),
  timezone: z.string(),
  gpoi: z.string(),
  password: z.string(),
  selected_products: z.array(bookSchema.pick({ id: true, name: true, pricing: true }).extend({ quantity: z.number() })),
  state: z.string(),
});

export type CheckoutSchema = z.infer<typeof checkoutSchema>;
