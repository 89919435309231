import { Suspense, lazy } from 'react';

import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Text, useBoolean, Backdrop } from 'atp-react-ui';
import { useNavigate } from 'react-router-dom';

import { IFrameDialog } from '@/_components/iframe';
import { config } from '@/_config';
import { ROUTES } from '@/_navigation';
import { invalidateCoursesQuery, useSubmitExamResultMutation } from '@/_prep-portal/courses/api';

import type { CourseModalProps } from './types';

const CelebrationPlayer = lazy(() => import('@/_shared/animations/celebration/celebration-player'));

type ResultSubmitConfirmationProps = CourseModalProps;

export function ResultSubmitConfirmation({ isOpen, id, name, passed, onClose }: ResultSubmitConfirmationProps) {
  const [isLoading, loading] = useBoolean();
  const [showSuccessMessage, successMessage] = useBoolean();
  const [showFailureMessage, failureMessage] = useBoolean();
  const [showContactAdvisors, contactAdvisorsModal] = useBoolean();

  const nav = useNavigate();

  const { mutateAsync: submitExamResult } = useSubmitExamResultMutation();

  const handleSubmit = async () => {
    loading.setTrue();
    try {
      await submitExamResult({ id, result: passed ? 'Pass' : 'Fail' });
      await invalidateCoursesQuery();
      onClose();
      if (passed) {
        successMessage.setTrue();
      } else {
        failureMessage.setTrue();
      }
    } catch (error) {
      console.error(error);
    }
    loading.setFalse();
  };

  const handleAnimationEnd = () => {
    successMessage.setFalse();
  };

  const onCloseFailureModal = () => {
    failureMessage.setFalse();
  };

  const handleContactAdvisorsClick = () => {
    failureMessage.setFalse();
    contactAdvisorsModal.setTrue();
  };

  const handleReviewResourcesClick = () => {
    nav(ROUTES.UNREGISTERED.path);
    failureMessage.setFalse();
  };

  const onCloseContactAdvisorsModal = () => {
    contactAdvisorsModal.setFalse();
  };

  return (
    <>
      {showSuccessMessage && (
        <Backdrop>
          <Suspense fallback={<div className="hidden" />}>
            <CelebrationPlayer callback={handleAnimationEnd} />
          </Suspense>
        </Backdrop>
      )}

      <IFrameDialog
        open={showContactAdvisors}
        onClose={onCloseContactAdvisorsModal}
        iframeProps={{
          urlObj: {
            link: config.upComingExamSupportLink,
          },
        }}
      />

      <Modal onClose={onClose} open={isOpen} disableInternalClosing={isLoading}>
        <ModalHeader>
          Submit Exam result
          <Text as="caption" className="mt-2 uppercase text-primary">
            PREP COURSE
          </Text>
          <Text as="caption-medium-bold" className="mt-1 text-accent-light">
            {name}
          </Text>
          {/* // TODO: Uncomment this when we have instructor name */}
          {/* <Text as="caption" className="mt-0.5 text-accent-light">
            Instructor: {subTitle}
          </Text> */}
        </ModalHeader>
        <ModalBody>
          {passed ? (
            <Text as="caption" className="text-sm font-normal leading-tight">
              Are you sure you want to report a <span className="text-primary">passed</span> result?
            </Text>
          ) : (
            <Text as="caption" className="text-sm font-normal leading-tight">
              Are you sure you want to report that you <span className="text-primary">didn&apos;t pass the exam</span>?
            </Text>
          )}
          <Text as="caption" className="mt-2 text-accent-light">
            Submitting incorrect results will affect your overall success.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button modal iconLeft={CheckIcon} onClick={handleSubmit} showLoader={isLoading}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>

      <Modal onClose={onCloseFailureModal} open={showFailureMessage}>
        <ModalHeader>Your journey continues...</ModalHeader>
        <ModalBody>
          Earning college credits is challenging, and we have additional resources available to support you at no
          charge.
        </ModalBody>
        <ModalFooter>
          <Button modal onClick={handleContactAdvisorsClick}>
            Contact our Advisors
          </Button>
          <Button modal onClick={handleReviewResourcesClick}>
            Review course resources
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
